import Logo from "../../assets/images/mandal-white-scaled-without-bg.png";
import {
  FacebookFilled,
  TwitterSquareFilled,
  YoutubeFilled,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import youtube from "../../assets/images/msf/youtube.png";
import twitter from "../../assets/images/msf/twitter.png";
import fb from "../../assets/images/msf/fb.png";
import { Card, Statistic, Row, Col, Divider } from "antd";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const dataMock = [
  { name: "Мандал Глобал Инвестмент", value: 1, color: "#C13C37" },
  { name: "Мандал Правит Экюити", value: 10, color: "#9585CF" },
  { name: "Мандал Хуримтлал Хос", value: 16, color: "#F9B719" },
  { name: "Даатгалын сан", value: 45, color: "#56B6C4" },
  { name: "Мандал ирээдүйн өсөлт ХОС", value: 28, color: "#9567E0" },
];

const About = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="text-4xl font-bold text-purple-800 mt-10 ml-10">
        Мандал Ассет Менежмент компанийн тухай
      </div>
      <span className="mt-4 mx-10 font-medium">
        “Мандал Санхүүгийн Нэгдэл” ХХК-ийн охин компани болох “Мандал Ассет
        Менежмент ҮЦК” ХХК нь 2011 онд байгуулагдсан бөгөөд Үнэт цаасны зах
        зээлийн тухай хууль, Хөрөнгө оруулалтын сангийн тухай хуульд заасан
        “хөрөнгө оруулалтын менежментийн үйл ажиллагаа эрхлэх” тусгай
        зөвшөөрөлтэй үнэт цаасны компани билээ. Бид эрсдэлийн удирдлагад
        суурилсан, урт хугацааны хөрөнгө оруулагч бөгөөд тогтвортой,стратегийн
        хөрөнгө оруулалтын боломжуудад төвлөрөн ажилладаг.
      </span>

      <div className="m-10">
        <div className="asset-management-dashboard w-full h-full">
          <div className="gutter-24 flex">
            <Col span={11} className="bg-white">
              <div className="bg-[#06114F] h-3"></div>
              <div className="ml-10 mt-3 text-xl font-bold">
                Удирдаж буй хөрөнгө
              </div>
              <div className="mt-4 mb-2 bg-[#6F3096] h-16 text-4xl font-bold text-white text-center pt-2">
                ₮209.7 тэрбум
              </div>
              <Row gutter={24}>
                <Col span={13} className="text-white leading-3">
                  <div className="h-24 flex">
                    <div className="bg-[#9585CF] h-20 w-28 text-center pt-2">
                      <span className="font-bold text-2xl">MFG</span>
                      <span className="text-[10px]">
                        <br />
                        Мандал ирээдүйн өсөлт
                      </span>
                    </div>
                    <div className="bg-[#9585CF] h-20 w-28 ml-2 text-center pt-2">
                      <span className="font-bold text-2xl">MFE</span>
                      <span className="text-[10px]">
                        <br />
                        Мандал Праявит Экүити
                      </span>
                    </div>
                  </div>
                  <div className="h-24 flex">
                    <div className="bg-[#9585CF] h-20 w-28 text-center pt-2">
                      <span className="font-bold text-2xl">MSF</span>
                      <span className="text-[10px]">
                        <br />
                        Мандал Хуримтлал
                      </span>
                    </div>
                    <div className="bg-[#9585CF] h-20 w-28 ml-2 text-center pt-2">
                      <span className="font-bold text-2xl">MGF</span>
                      <span className="text-[10px]">
                        <br />
                        Мандал Глобал{" "}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={11}>
                  <div className="h-44">
                    <div className="bg-[#9585CF] h-full w-full flex items-center justify-center text-xl text-center font-bold text-white">
                      Даатгалын сан
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="h-32 mt-2 bg-[#6F3096]">
                <div className="pt-4 pl-3">
                  <span className="text-xl text-white">
                    Мандал Ассет ХОМК нь нийт 4 төрлийн ХОС-н 209.7 төгрөгийн
                    хөрөнгийг итгэмжлэн удирдаж байна.
                  </span>
                </div>
              </div>
            </Col>
            <Col span={1}></Col>
            <Col span={11} className="bg-white">
              <div className="bg-[#06114F] h-3"></div>
              <div className="ml-10 mt-3 text-xl font-bold">
                Хөрөнгө оруулалтын сан, хувиар
              </div>
              <PieChart width={500} height={400}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={dataMock}
                  cx={200}
                  cy={200}
                  outerRadius={80}
                  fill="#8884d8"
                  label={(entry) => entry.name}
                >
                  {dataMock.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </Col>
          </div>
        </div>
      </div>
      <footer className="bg-gray-200 p-5 flex items-center justify-between text-justify">
        <div className="flex-1 flex items-center mr-10" style={{ flex: 4 }}>
          <img src={Logo} alt="Company Logo" className="h-20 w-20 mr-3 mb-3" />
          <div className="flex flex-col">
            <span className="flex-1 font-medium mt-4 text-justify">
              Мандал Ассет Менежмент нь хөрөнгө оруулагчдын эрх ашгийг нэн
              түрүүнд тавьж, мэдлэгт суурилсан оновчтой үйл ажиллагаа, эрсдэлийн
              удирдлагын тогтолцоогоор дамжуулж өндөр өгөөж бий болгохыг
              эрмэлздэг.
            </span>
            <span className="flex-1 mt-2 text-gray-500 font-medium">
              © Мандал Ассет Менежмент. Бүх эрх хуулиар хамгаалагдсан. 2021 он
            </span>
          </div>
        </div>
        <div className="flex-1 flex items-center" style={{ flex: 3 }}>
          <div className="flex flex-col">
            <span className="flex-1 font-bold">Холбоо барих</span>
            <span className="flex-1 mt-1 font-medium text-justify">
              Монгол улс, Улаанбаатар хот, Blue-sky-н чанх урд байрлах Meru
              Tower-н 6 давхарт 602 тоот
            </span>
            <div className="text-2xl">
              <a
                href="https://www.facebook.com/mandalasset"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="GooglePlay" className="h-6 mr-2" />
              </a>
              <a
                href="https://twitter.com/MandalAsset"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="GooglePlay" className="h-6 mr-2" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCP3xR9ltHQoGZi9lQAMl1Gw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="GooglePlay" className="h-6 mr-2" />
              </a>
            </div>
            <div className="mt-1">
              <PhoneOutlined className="text-xl" />
              <span className="ml-1">+976-7575 9911</span>
              <MailOutlined className="text-xl ml-4" />
              <span className="ml-1">support@mandalasset.mn</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
