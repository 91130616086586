import { Card, Menu, message } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getEmployee } from "../../features/employee/actions";

const EmployeeEdit = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, data, error } = useAppSelector((state) => state.employee);

  const menuItems: ItemType[] = [
    {
      key: `/company/employees/${id}`,
      label: "Мэдээлэл",
    },
    {
      key: `/company/employees/${id}/transactions`,
      label: "Гүйлгээ",
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getEmployee(id));
    }
  }, [id]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <>
      <Menu
        items={menuItems}
        direction="ltr"
        mode="horizontal"
        onClick={async (info) => {
          navigate(info.key);
        }}
        selectedKeys={[
          window.location.pathname.split("/").pop() === "transactions"
            ? `/company/employees/${id}/transactions`
            : `/company/employees/${id}`,
        ]}
        style={{ margin: 10 }}
      />
      <div style={{ margin: 10 }}>
        <Outlet />
      </div>
    </>
  );
};

export default EmployeeEdit;
