import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api";
import {
  IEmployee,
  IFailedEmployee,
  ITransactionDetail,
} from "../../interfaces";
import Employees from "./Employees";
import ExcelUploader from "./ExcelUploader";
import FailedEmployees from "./FailedEmlployees";

interface EmailStatus {
  email: string;
  status: "success" | "failed";
}

const CompanyEmployees = () => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<
    (IEmployee & ITransactionDetail)[]
  >([]);
  const [failed, setFailed] = useState<IFailedEmployee[]>([]);
  const [isFailedOpen, setFailedOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (failed.length > 0) {
      setFailedOpen(true);
    }
  }, [failed]);

  const loadEmployees = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/employee");
      setEmployees(data);
    } catch (err: any) {
      message.error(err?.response?.data?.message || err?.message);
    }
    setLoading(false);
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "И-мэйл илгээхдээ итгэлтэй байна уу?",
      content:
        "Компанид бүртгэлтэй бүх ажилчид руу нууц үг шинэчлэх и-мэйл илгээгдэнэ!",
      okText: "Тийм",
      okType: "danger",
      cancelText: "Үгүй",
      onOk() {
        sendBulkEmail();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showReportModal = (results: EmailStatus[]) => {
    Modal.info({
      title: "Илгээгдсэн эсэх мэдээлэл",
      width: 520,
      content: (
        <div>
          {results.map((result, index) => (
            <div
              key={index}
              style={{ marginBottom: 10, borderBottom: "1px solid #E2E8F0" }}
            >
              <p>
                <strong>email:</strong> {result.email}
                <strong className="ml-4">status:</strong>
                {result.status === "success" ? " Амжилттай" : " Амжилтгүй"}
              </p>
            </div>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const sendBulkEmail = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.post("/employee/bulk-email", {
        sendEmail: true,
      });
      message.success("и-мэйл илгээгдэж дууслаа");
      showReportModal(data);
    } catch (error: any) {
      message.error(error?.response?.data?.message || error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  return (
    <div style={{ margin: 10 }}>
      <Card style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ExcelUploader
            setLoading={setLoading}
            loadEmployees={loadEmployees}
            setFailed={setFailed}
          />
          <div>
            <Button
              id="send-emails"
              type="primary"
              onClick={showConfirm}
              className="mr-2"
            >
              Баталгаажуулах и-мэйл илгээх
            </Button>
            <Button
              id="new-employee"
              icon={<PlusOutlined />}
              onClick={() => navigate("/company/employees/new")}
            >
              Ажилтан нэмэх
            </Button>
          </div>
        </div>
      </Card>
      <Employees
        employees={employees}
        loading={loading}
        setLoading={setLoading}
      />
      <FailedEmployees
        isOpen={isFailedOpen}
        close={() => setFailedOpen(false)}
        failed={failed}
      />
    </div>
  );
};

export default CompanyEmployees;
