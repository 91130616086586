import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://company.investmandal.com",
  headers: { "Content-Type": "application/json" },
});

apiClient.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem("authToken")}`;
  return config;
});
