import { createSlice } from "@reduxjs/toolkit";
import { getNav } from "./navActions";

interface IState {
  nav: number;
  loading: boolean;
  error?: string;
}

const initialState: IState = {
  nav: 0.98,
  loading: false,
  error: undefined,
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNav.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(getNav.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.nav = payload;
    });

    builder.addCase(getNav.rejected, (state, { payload }: { payload: any }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export default navSlice.reducer;
