import { createSlice } from "@reduxjs/toolkit";
import { ICompany, ITransaction } from "../../interfaces";
import { companyLogin, companyGetMe } from "./actions";

const authToken = localStorage.getItem("authToken") || undefined;

interface IState {
  info?: ICompany;
  lastTransaction?: ITransaction;
  loading: boolean;
  error?: string;
  authToken?: string;
}

const initialState: IState = {
  info: undefined,
  loading: false,
  error: undefined,
  authToken,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("authToken");
      state = {
        ...initialState,
        authToken: undefined,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(companyLogin.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(companyLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.info = payload;
      state.authToken = payload.accessToken;
    });

    builder.addCase(
      companyLogin.rejected,
      (state, { payload }: { payload: any }) => {
        state.loading = false;
        state.error = payload;
      },
    );

    builder.addCase(companyGetMe.pending, (state) => {
      state.loading = false;
      state.error = undefined;
    });

    builder.addCase(companyGetMe.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.info = payload;
      state.lastTransaction = payload.transaction;
    });

    builder.addCase(
      companyGetMe.rejected,
      (state, { payload }: { payload: any }) => {
        state.loading = false;
        state.error = payload;
      },
    );
  },
});

export const { logout } = companySlice.actions;

export default companySlice.reducer;
