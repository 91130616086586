import React, { useContext, useEffect, useState } from "react";
import Joyride, { ACTIONS, Step } from "react-joyride";
import { Route, Routes, useLocation } from "react-router-dom";
import Authentication from "./pages/Auth";
import ConfirmAccount from "./pages/Auth/ConfirmAccount";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import Employee from "./pages/Employee";
import EmployeeEdit from "./pages/Employee/Edit";
import EmployeeNew from "./pages/Employee/New";
import EmployeeTransactions from "./pages/Employee/Transactions";
import CompanyEmployees from "./pages/Employees";
import CompanyHome from "./pages/CompanyHome";
import CompanyInfo from "./pages/CompanyInfo";
import CompanyTransactions from "./pages/Transactions";
import Details from "./pages/Transactions/Detail";
import NewTransaction from "./pages/Transactions/NewTransaction";
import Video from "./pages/Video";
import { JoyContext } from "./providers";
import EmployeeHome from "./pages/EmployeeHome";
import About from "./pages/EmployeeHome/About";
import MSF from "./pages/EmployeeHome/MSF";
import TransactionList from "./pages/EmployeeTransactionList/TransactionList";

const tourSteps = {
  home: [
    {
      target: "[data-menu-id='menu-/company']",
      content: "Байгууллагын хуримтлалын мэдээлэл",
    },
    {
      target: "[data-menu-id='menu-/company/employees']",
      content: "Бүх ажилтны хуримтлалын мэдээлэл",
    },
    {
      target: "[data-menu-id='menu-/company/transactions']",
      content: "Гүйлгээний түүх болон шинээр хуримтлал хийх",
    },
    {
      target: "[data-menu-id='menu-/']",
      content: "Системээс гарах",
    },
  ],
  employees: [
    {
      target: "#download-employee-excel",
      content: "Ажилтны мэдээллийг шивэх эксэл загвар татаж авах",
    },
    {
      target: "#upload-employees",
      content: "Шинээр ажилтан нэмэх болон ажилтны мэдээлэл засахад ашиглана",
    },
    {
      target: "#new-employee",
      content: "Шинэ ажилтан гараар нэмэх",
    },
    {
      target: ".employee_lastname",
      content:
        "Тухайн ажилтны хувийн мэдээллийг харах, засах мөн хуримтлалын түүхийг харах боломжтой",
    },
  ],
  transactions: [
    {
      target: "#transaction-range",
      content: "Хуримтлалын түүх огноогоор харах",
    },
    {
      target: "#transaction-add",
      content: "Та үүн дээр дарж нэмэлт хуримтлал хийх боломжтой",
    },
    {
      target: ".transaction_info",
      content: "Хуримтлалын нэхэмжлэх эсвэл задаргааг харж болно.",
    },
  ],
};

const App: React.FC = () => {
  const location = useLocation();

  const [steps, setSteps] = useState<Step[]>([]);
  const { showJoyride, setShowJoyride } = useContext(JoyContext);
  useEffect(() => {
    const stepKey = location.pathname.split("/")[2]
      ? location.pathname.split("/")[2]
      : "home";
    setSteps(tourSteps[stepKey as keyof typeof tourSteps]);
  }, [location]);

  return (
    <>
      <Joyride
        steps={steps}
        run={showJoyride}
        continuous={true}
        callback={(data) => {
          if (data.action === ACTIONS.RESET) {
            setShowJoyride(false);
          }
        }}
        locale={{
          close: "Хаах",
          back: "Буцах",
          last: "Сүүлийнх",
          next: "Дараагийн",
          open: "Нээх",
          skip: "Алгасах",
        }}
      />
      <Routes>
        <Route path="/" element={<Authentication />}>
          <Route index element={<Login />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password/*" element={<ResetPassword />} />
          <Route path="/confirm-company/*" element={<ConfirmAccount />} />
          <Route path="/confirm-employee*" element={<ConfirmAccount />} />
        </Route>
        <Route path="/company" element={<CompanyHome />}>
          <Route index element={<CompanyInfo />} />
          <Route path="/company/employees" element={<CompanyEmployees />} />
          <Route path="/company/employees/new" element={<EmployeeNew />} />
          <Route path="/company/employees/:id" element={<Employee />}>
            <Route index element={<EmployeeEdit />} />
            <Route
              path="/company/employees/:id/transactions"
              element={<EmployeeTransactions />}
            />
          </Route>
          <Route
            path="/company/transactions"
            element={<CompanyTransactions />}
          />
          <Route
            path="/company/transactions/new"
            element={<NewTransaction />}
          />
          <Route path="/company/transactions/:id" element={<Details />} />
          <Route path="/company/videos" element={<Video />} />
        </Route>
        <Route path="/employee" element={<EmployeeHome />}>
          <Route index element={<TransactionList />} />
          <Route path="/employee/about" element={<About />} />
          <Route path="/employee/msf" element={<MSF />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
