import React, { useState } from "react";
import Logo from "../../assets/images/mandal-white-scaled-without-bg.png";
import {
  FacebookFilled,
  TwitterSquareFilled,
  YoutubeFilled,
  PhoneOutlined,
  MailOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { PieChart, Pie, Cell } from "recharts";
import youtube from "../../assets/images/msf/youtube.png";
import twitter from "../../assets/images/msf/twitter.png";
import fb from "../../assets/images/msf/fb.png";
import checkedIcon from "../../assets/images/msf/icons8-check-96.png";
import mam from "../../assets/images/msf/MAM.png";
import asset from "../../assets/images/msf/asset.png";
import capital from "../../assets/images/msf/capital.png";
import insurance from "../../assets/images/msf/insurance.png";
import life from "../../assets/images/msf/life.png";
import tenger from "../../assets/images/msf/tenger.png";
import hurimtlal from "../../assets/images/msf/hurimtlal.png";
import getGoogle from "../../assets/images/msf/getGoolgePlay.png";
import appStore from "../../assets/images/msf/downloadAppStore.png";

const data = [
  { name: "bond", value: 48.32 },
  { name: "saving", value: 51.68 },
];
const dataCurr = [
  { name: "dollar", value: 7.0 },
  { name: "mnt", value: 93.0 },
];
const COLORS = ["#57B7EF", "#F9B719"];

const MSF = () => {
  const [isCurrencyView, setIsCurrencyView] = useState(false);
  const items = [1, 2, 3, 4];

  const handleClick = () => {
    setIsCurrencyView(!isCurrencyView);
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex">
        <div className="my-10 ml-10 w-[730px] h-[620px]">
          <div className="flex flex-row">
            <div className="">
              <img src={mam} alt="MAM" className="ml-[184px]" />
              <div className="flex">
                <div className="w-[160px]">
                  <div>
                    <div className="flex items-center leading-4 mt-[36px]">
                      <img src={insurance} alt="insurance" className="h-14 " />
                      <span className="font-serif font-medium text-[#2E3D7C] ml-1">
                        MANDAL INSURANCE
                      </span>
                    </div>
                    <div className="w-32 text-xs font-sans text-slate-500 mt-1">
                      <span>Хамгийн том даатгалын компани</span>
                    </div>
                  </div>
                  <div className="mt-[21px]">
                    <div className="flex items-center leading-4">
                      <img
                        src={tenger}
                        alt="tenger_insurance"
                        className="h-8 ml-1"
                      />
                      <span className="font-sans font-medium text-[#000084] ml-1">
                        TENGER <br />
                        INSURANCE
                      </span>
                    </div>
                    <div className="w-32 text-xs font-sans text-slate-500 mt-1">
                      <span>Топ 5 даатгалын компанийн 1</span>
                    </div>
                  </div>
                  <div className="mt-[18px]">
                    <div className="flex items-center leading-4">
                      <img src={life} alt="mandal_life" className="h-14" />
                      <span className="font-serif font-medium text-[#FF6635] ml-1">
                        MANDAL
                        <br />
                        LIFE
                      </span>
                    </div>
                    <div className="w-32 text-xs font-sans text-slate-500 mt-1">
                      <span>Амь даатгалын 2 компанийн 1</span>
                    </div>
                  </div>
                  <div className="mt-[17px]">
                    <div className="flex items-center leading-4">
                      <img src={asset} alt="mandal_asset" className="h-14" />
                      <span className="font-serif font-medium text-[#7C2869] ml-1">
                        MANDAL
                        <br />
                        ASSET
                      </span>
                    </div>
                    <div className="w-32 text-xs font-sans text-slate-500 mt-1">
                      <span>Хөрөнгийн зах зээл №1 компани</span>
                    </div>
                  </div>
                  <div className="mt-[17px]">
                    <div className="flex items-center leading-4">
                      <img
                        src={capital}
                        alt="mandal_capital"
                        className="h-14"
                      />
                      <span className="font-serif font-medium text-[#0F9E90] ml-1">
                        MANDAL
                        <br />
                        CAPITAL
                      </span>
                    </div>
                    <div className="w-32 text-xs font-sans text-slate-500 mt-1">
                      <span>Топ 5-н үнэт цаасны компанийн 1</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "40px",
                      width: "2px",
                      marginLeft: 59,
                      marginTop: 10,
                    }}
                  />
                  <div className="flex items-center">
                    <CaretLeftOutlined className="text-[#57B7EF] text-l" />
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "30px",
                      }}
                    ></div>
                    <img src={checkedIcon} alt="icon" className="h-8" />
                  </div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "70px",
                      width: "2px",
                      marginLeft: 59,
                    }}
                  />
                  <div className="flex items-center">
                    <CaretLeftOutlined className="ml-[-2px] text-[#57B7EF] text-l" />
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "32px",
                      }}
                    ></div>
                    <img src={checkedIcon} alt="icon" className="h-8" />
                  </div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "70px",
                      width: "2px",
                      marginLeft: 59,
                    }}
                  />
                  <div className="flex items-center">
                    <CaretLeftOutlined className="ml-[-2px] text-[#57B7EF] text-l" />
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "32px",
                      }}
                    ></div>
                    <img src={checkedIcon} alt="icon" className="h-8" />
                  </div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "78px",
                      width: "2px",
                      marginLeft: 58,
                    }}
                  />
                  <div className="flex items-center">
                    <CaretLeftOutlined className="ml-[-2px] text-[#57B7EF] text-l" />
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "32px",
                      }}
                    ></div>
                    <img src={checkedIcon} alt="icon" className="h-8" />
                  </div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "80px",
                      width: "2px",
                      marginLeft: 58,
                    }}
                  />
                  <div className="flex items-center">
                    <CaretLeftOutlined className="ml-[-2px] text-[#57B7EF] text-l" />
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "32px",
                      }}
                    ></div>
                    <img src={checkedIcon} alt="icon" className="h-8" />
                  </div>
                </div>
                <div className="mt-[218px] ml-[23px] flex flex-row">
                  <div className="flex items-center">
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "60px",
                        marginLeft: -24,
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      borderLeft: "2px dotted #57B7EF",
                      height: "140px",
                      width: "2px",
                      marginLeft: "2px",
                      marginTop: "24px",
                    }}
                  />
                  <div className="flex items-center">
                    <div
                      style={{
                        borderTop: "2px dotted #57B7EF",
                        height: "2px",
                        width: "80px",
                        marginBottom: 280,
                      }}
                    ></div>
                  </div>
                  <CaretRightOutlined className="ml-[-2px] text-[#57B7EF] text-l mt-4" />
                </div>
              </div>
            </div>
            <div
              className="w-[300px] h-[480px] rounded-3xl shadow-xl bg-white ml-4"
              style={{ border: "1px solid #80D3F0" }}
            >
              <div className="m-10 text-4xl font-bold text-[#57B7F0] mb-4">
                Мандал <br />
                Хуримталын ард хэн байдаг <br /> вэ?
              </div>
              <div className="flex items-center leading-4 ml-10 mt-10">
                <img src={hurimtlal} alt="mandal_capital" className="h-14" />
                <span className="font-medium ml-1">
                  Мандал
                  <br />
                  Хуримтал
                </span>
              </div>
              <div className="ml-10 mt-20 w-40 text-x text-slate-500 mt-1">
                <span>Үрдэг биш хуримтлуулдаг апп</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="mt-10 w-[400px] h-[480px] rounded-3xl shadow-xl bg-white"
            style={{ border: "1px solid #80D3F0" }}
          >
            <div className="m-10 text-2xl font-bold text-[#57B7F0] mb-4">
              {isCurrencyView
                ? "Хуримтлалын багц валютаар"
                : "Хуримтлалын багц"}
            </div>
            <div className="ml-10 text-slate-600 w-64">
              Таны хуримтлал{" "}
              {isCurrencyView
                ? "дараах валютаас"
                : "дараах хөрөнгө оруулалтаас"}{" "}
              бүрдэж байна
            </div>
            <div className="flex items-center">
              <PieChart
                className={isCurrencyView ? "mt-[-110px]" : "mt-[-78px]"}
                width={300}
                height={300}
              >
                <Pie
                  data={isCurrencyView ? dataCurr : data}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {(isCurrencyView ? dataCurr : data).map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="grid justify-items-center">
              <table className="w-52">
                <tbody>
                  <tr className="grid grid-cols-2">
                    <td className="py-1 flex col-span-1">
                      <div className="h-2 w-2 mt-2 mr-2 rounded bg-chartColorA"></div>
                      {isCurrencyView ? "Ам.доллар" : "Бонд"}:
                    </td>
                    <td className="font-bold col-span-1 text-right">
                      {(isCurrencyView
                        ? dataCurr[0].value.toFixed(2)
                        : data[0].value) + "%"}
                    </td>
                  </tr>
                  <tr className="grid grid-cols-2">
                    <td className="py-1 flex col-span-1">
                      <div className="h-2 w-2 mt-2 mr-2 rounded bg-chartColorB"></div>
                      {isCurrencyView ? "Төгрөг" : "Хадгаламж"}:{" "}
                    </td>
                    <td className="font-bold col-span-1 text-right">
                      {(isCurrencyView
                        ? dataCurr[1].value.toFixed(2)
                        : data[1].value) + "%"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-xl text-center mt-4">
              <ArrowRightOutlined onClick={handleClick} />
            </div>
          </div>
          <div
            className="mt-20 ml-[-250px] grid grid-cols-4 w-[600px] rounded-1xl shadow-xl"
            style={{ border: "1px solid #D4D4D4" }}
          >
            <div className="col-span-4 flex flex-row mt-1">
              <div className="w-[50%] text-center">Аппликейшн татах</div>
              <div className="w-[50%] text-center">Аппликейшн ашиглах</div>
            </div>
            <div className="flex items-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.investm_app_v2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={getGoogle} alt="GooglePlay" className="h-[60px]" />
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="https://apps.apple.com/us/app/%D0%BC%D0%B0%D0%BD%D0%B4%D0%B0%D0%BB-%D1%85%D1%83%D1%80%D0%B8%D0%BC%D1%82%D0%BB%D0%B0%D0%BB/id1618191556"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="AppStore" className="h-[56px]" />
              </a>
            </div>
            <div className="flex items-center ml-10 text-center">
              <a href="https://www.youtube.com/playlist?list=PLx80Dq_l5ikMYRKB5f_WG763Z0kD3dnO4">
                Заавар бичлэг үзэх
              </a>
            </div>
            <div className="flex items-center ml-10">
              <a
                href="https://www.youtube.com/watch?v=Sph8UCkbkfo"
                className="text-center"
              >
                Танилцуулга үзэх
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-gray-200 p-5 flex items-center justify-between text-justify">
        <div className="flex-1 flex items-center mr-10" style={{ flex: 4 }}>
          <img src={Logo} alt="Company Logo" className="h-20 w-20 mr-3 mb-3" />
          <div className="flex flex-col">
            <span className="flex-1 font-medium mt-4 text-justify">
              Мандал Ассет Менежмент нь хөрөнгө оруулагчдын эрх ашгийг нэн
              түрүүнд тавьж, мэдлэгт суурилсан оновчтой үйл ажиллагаа, эрсдэлийн
              удирдлагын тогтолцоогоор дамжуулж өндөр өгөөж бий болгохыг
              эрмэлздэг.
            </span>
            <span className="flex-1 mt-2 text-gray-500 font-medium">
              © Мандал Ассет Менежмент. Бүх эрх хуулиар хамгаалагдсан. 2021 он
            </span>
          </div>
        </div>
        <div className="flex-1 flex items-center" style={{ flex: 3 }}>
          <div className="flex flex-col">
            <span className="flex-1 font-bold">Холбоо барих</span>
            <span className="flex-1 mt-1 font-medium text-justify">
              Монгол улс, Улаанбаатар хот, Blue-sky-н чанх урд байрлах Meru
              Tower-н 6 давхарт 602 тоот
            </span>
            <div className="text-2xl">
              <a
                href="https://www.facebook.com/mandalasset"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="GooglePlay" className="h-6 mr-2" />
              </a>
              <a
                href="https://twitter.com/MandalAsset"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="GooglePlay" className="h-6 mr-2" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCP3xR9ltHQoGZi9lQAMl1Gw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="GooglePlay" className="h-6 mr-2" />
              </a>
            </div>
            <div className="mt-1">
              <PhoneOutlined className="text-xl" />
              <span className="ml-1">+976-7575 9911</span>
              <MailOutlined className="text-xl ml-4" />
              <span className="ml-1">support@mandalasset.mn</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MSF;
