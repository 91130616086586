import * as XLSX from "xlsx";
import { ExcelProps } from "../interfaces";

export const downloadExcel = async () => {
  try {
    fetch("/zagvar.xlsx").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.target = "_blank";
        alink.download = "zagvar.xlsx";
        document.body.appendChild(alink);
        alink.click();
        document.body.removeChild(alink);
        window.URL.revokeObjectURL(fileURL);
      });
    });
  } catch (error) {}
};

export const excelToJson = (
  ev: React.ChangeEvent<HTMLInputElement>,
): Promise<ExcelProps[]> => {
  ev.preventDefault();
  return new Promise((resolve, reject) => {
    if (ev.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target!.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          if (
            !worksheet["B1"] ||
            worksheet["B1"].w !== "Овог" ||
            !worksheet["C1"] ||
            worksheet["C1"].w !== "Нэр" ||
            !worksheet["D1"] ||
            worksheet["D1"].w !== "Регистр" ||
            !worksheet["E1"] ||
            worksheet["E1"].w !== "Имэйл" ||
            !worksheet["F1"] ||
            worksheet["F1"].w !== "Утас" ||
            !worksheet["G1"] ||
            worksheet["G1"].w !== "Цалингаас" ||
            !worksheet["H1"] ||
            worksheet["H1"].w !== "Байгууллагаас"
          ) {
            throw new Error(
              "Баганууд таарахгүй байна. Загварын дагуу бөглөнө үү",
            );
          }

          worksheet["A1"].w = "order";
          worksheet["B1"].w = "lastName";
          worksheet["C1"].w = "firstName";
          worksheet["D1"].w = "regNumber";
          worksheet["E1"].w = "email";
          worksheet["F1"].w = "phone";
          worksheet["G1"].w = "fromSalary";
          worksheet["H1"].w = "fromCompany";

          const json: ExcelProps[] =
            XLSX.utils.sheet_to_json<ExcelProps>(worksheet);

          resolve(
            json.map((data) => ({
              ...data,
              phone: data.phone ? data.phone.toString() : "",
              email: data.email ? data.email.toString() : "",
            })),
          );
        } catch (err: any) {
          reject(err.message || "Загварын дагуу бөглөнө үү");
        }
      };
      reader.readAsArrayBuffer(ev.target.files[0]);
    }
  });
};
