import { Button, Card, Col, Input, message, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../api";
import { ExcelProps, IFailedEmployee } from "../../interfaces";
import { downloadExcel, excelToJson, numberWithCommas } from "../../utils";
import FailedEmployees from "../Employees/FailedEmlployees";

const columns: ColumnsType<ExcelProps> = [
  {
    title: "№",
    render: (_: any, record) => record.order,
  },
  {
    title: "Овог",
    dataIndex: "lastName",
  },
  {
    title: "Нэр",
    dataIndex: "firstName",
  },
  {
    title: "Регистр",
    dataIndex: "regNumber",
  },
  {
    title: "Утас",
    dataIndex: "phone",
  },
  {
    title: "Имэйл",
    dataIndex: "email",
  },
  {
    title: "Ажилтнаас",
    dataIndex: "fromSalary",
    render: (digit: number) => numberWithCommas(digit),
  },
  {
    title: "Байгууллагаас",
    dataIndex: "fromCompany",
    render: (digit: number) => numberWithCommas(digit),
  },
];

const NewTransaction = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(dayjs().format("YYYY-MM"));
  const [calculation, setCalculation] = useState<{
    value: number;
    employees: number;
  }>();
  const [json, setJson] = useState<ExcelProps[]>([]);
  const [isFailedOpen, setFailedOpen] = useState(false);
  const [failed, setFailed] = useState<IFailedEmployee[]>([]);

  useEffect(() => {
    calculateTransaction();
  }, []);

  useEffect(() => {
    if (failed.length > 0) {
      setFailedOpen(true);
    }
  }, [failed]);

  const createTransaction = async () => {
    setLoading(true);
    if (json.length > 0) {
      try {
        const { data } = await apiClient.post("/transaction/buy/json", {
          description,
          employees: json,
        });

        navigate(`/company/transactions/${data.id}`);
      } catch (err: any) {
        message.error(err.message);
      }
      setLoading(false);
      return;
    }

    try {
      const { data } = await apiClient.post("/transaction/buy", {
        description,
      });
      navigate(`/company/transactions/${data.id}`);
    } catch (err: any) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const calculateTransaction = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/transaction/calculate");
      setCalculation(data);
    } catch (err: any) {
      message.error(err.message);
    }
    setLoading(false);
  };

  const prepareExcel = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    try {
      const json = await excelToJson(ev);
      setJson(json);
    } catch (err: any) {
      message.error(err);
    }
    //@ts-expect-error
    ev.target.value = null;
  };

  const getValue = () =>
    numberWithCommas(
      json.reduce((a, b) => a + (b.fromCompany + b.fromSalary), 0) ||
        calculation?.value,
    );

  return (
    <>
      <Card className="m-2">
        <h1>Хуримтлал нэмэх</h1>
      </Card>
      <Card className="m-2">
        <Row>
          <Col span={10}>Гүйлгээний дүн:</Col>
          <Col>{getValue()}₮</Col>
        </Row>
        <Row className="mt-2">
          <Col span={10}>Хуримтлуулах ажилтны тоо:</Col>
          <Col>{json.length || calculation?.employees}</Col>
        </Row>
        <Row style={{ display: "flex", alignItems: "center", marginTop: 6 }}>
          <Col span={10}>Хуримтлалын тайлбар:</Col>
          <Col>
            <Input
              name="description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              maxLength={50}
            />
          </Col>
        </Row>
        {json.length > 0 && (
          <Table
            scroll={{ x: true }}
            className="mt-4"
            dataSource={json}
            columns={columns}
            loading={loading}
            rowKey="order"
            pagination={{
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [50, 100, 200],
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 32,
          }}
        >
          <div>
            <Button loading={loading} onClick={createTransaction}>
              Хуримтлуулах
            </Button>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            {json.length > 0 && (
              <Button danger onClick={() => setJson([])}>
                Болих
              </Button>
            )}
            <div id="upload-employees" className="mx-2">
              <label className="ant-btn ant-btn-primary" htmlFor="file">
                Excel илгээх
              </label>
              <input
                id="file"
                type="file"
                className="hidden"
                onChange={prepareExcel}
              />
            </div>
            <Button id="download-employee-excel" onClick={downloadExcel}>
              Excel загвар татаж авах
            </Button>
          </div>
        </div>
      </Card>

      <FailedEmployees
        isOpen={isFailedOpen}
        close={() => setFailedOpen(false)}
        failed={failed}
      />
    </>
  );
};

export default NewTransaction;
