import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../api";
import { ILogin } from "../../interfaces";

export const companyLogin = createAsyncThunk(
  "company/login",
  async (dto: ILogin, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post("/auth-company/login", dto);
      localStorage.setItem("authToken", data.accessToken);
      return data;
    } catch (error: any) {
      if (error.response?.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const companyGetMe = createAsyncThunk("company/me", async () => {
  const { data } = await apiClient.get("/company/me");
  return data;
});
