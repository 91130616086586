import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { companyGetMe } from "../../features/company/actions";
import { employeeGetMe } from "../../features/employee/actions";
import Logo from "../../assets/images/mandal-white-scaled.png";
import { Card } from "antd";

const Authentication = () => {
  const dispatch = useAppDispatch();
  const { loading: companyLoading, info: companyInfo } = useAppSelector(
    (state) => state.company
  );
  const { loading: employeeLoading, info: employeeInfo } = useAppSelector(
    (state) => state.employee
  );

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(companyGetMe());
    dispatch(employeeGetMe());
  }, [dispatch]);

  useEffect(() => {
    if (companyInfo) {
      navigate("/company");
    }
    if (employeeInfo) {
      navigate("/employee");
    }
  }, [navigate, companyInfo, employeeInfo]);

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <div
        style={{
          flex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <Outlet />
        </Card>
      </div>
      <div style={{ flex: 1, backgroundColor: "#153a73" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              padding: 50,
              backgroundColor: "white",
              left: "-20%",
              border: "1px solid #f0f0f0",
              borderRadius: 1,
            }}
          >
            <img src={Logo} style={{ height: 200, width: 200 }} />
            <h1 style={{ textAlign: "center", fontWeight: 600 }}>
              Ажилтны
              <br />
              хуримтлал
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
