import React, { createContext, useState } from "react";

type Props = {
  showJoyride: boolean;
  setShowJoyride(_: boolean): void;
};

export const JoyContext = createContext<Props>({
  showJoyride: false,
  setShowJoyride(_) {},
});

const JoyProvider = ({ children }: { children: React.ReactNode }) => {
  const [showJoyride, setShowJoyride] = useState(false);

  return (
    <JoyContext.Provider
      value={{
        showJoyride,
        setShowJoyride,
      }}
    >
      {children}
    </JoyContext.Provider>
  );
};
export default JoyProvider;
