import { Button, message } from "antd";
import React from "react";
import { apiClient } from "../../api";
import { IFailedEmployee } from "../../interfaces";
import { downloadExcel, excelToJson } from "../../utils";
interface IProps {
  setLoading(loading: boolean): void;
  loadEmployees(): void;
  setFailed(failed: IFailedEmployee[]): void;
}

const ExcelUploader: React.FC<IProps> = ({
  setLoading,
  loadEmployees,
  setFailed,
}) => {
  const upload = async (json: any) => {
    setLoading(true);
    try {
      const { data } = await apiClient.post("/employee/multiple", {
        employees: json,
      });
      setFailed(data.failed);
      message.success("Амжилттай");
      loadEmployees();
    } catch (error) {}
    setLoading(false);
  };

  const prepareExcel = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    try {
      const json = await excelToJson(ev);
      upload(json);
    } catch (err: any) {
      message.error(err);
    }
  };

  return (
    <div
      style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
    >
      <div id="upload-employees">
        <label className="ant-btn ant-btn-primary" htmlFor="file">
          Excel илгээх
        </label>
        <input
          id="file"
          type="file"
          style={{ display: "none" }}
          onChange={prepareExcel}
        />
      </div>
      <Button
        id="download-employee-excel"
        style={{ marginLeft: ".5rem" }}
        onClick={downloadExcel}
      >
        Excel загвар татаж авах
      </Button>
    </div>
  );
};

export default ExcelUploader;
