import { Button, Form, Input, message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { companyLogin } from "../../features/company/actions";
import { employeeLogin } from "../../features/employee/actions";
import { ILogin } from "../../interfaces";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const companyState = useAppSelector((state) => state.company);
  const employeeState = useAppSelector((state) => state.employee);
  const [isCompany, setIsCompany] = useState(true);

  const onSubmit = (dto: ILogin) => {
    if (isCompany) {
      dispatch(companyLogin(dto));
    } else {
      dispatch(employeeLogin(dto));
    }
  };

  useEffect(() => {
    if (companyState.error) message.error(companyState.error);
    if (employeeState.error) message.error(employeeState.error);
  }, [companyState.error, employeeState.error]);

  const handleToggle = (checked: boolean) => {
    setIsCompany(checked);
  };

  return (
    <div style={{ minWidth: 450 }}>
      <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
        <h1
          style={{
            fontWeight: "bold",
            fontSize: 40,
            padding: "2rem",
            textAlign: "center",
          }}
        >
          Нэвтрэх
        </h1>
      </div>
      <div style={{ alignSelf: "center", flex: 2, width: "100%" }}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSubmit}
          autoComplete="off"
          disabled={companyState.loading || employeeState.loading}
        >
          <div style={{ position: "relative" }}>
            <Switch
              checkedChildren="Байгууллага"
              unCheckedChildren="Ажилтан"
              checked={isCompany}
              onChange={handleToggle}
              style={{ position: "absolute", top: -42, left: 76 }}
            />
            <Form.Item
              label={<span>Имэйл</span>}
              name="email"
              rules={[{ message: "Имэйлээ оруулна уу" }]}
            >
              <Input
                type="email"
                onInput={(e: any) =>
                  (e.target.value = e.target.value.toLowerCase())
                }
              />
            </Form.Item>
          </div>

          <Form.Item
            label={<span>Нууц үг</span>}
            style={{ color: "white" }}
            name="password"
            rules={[{ message: "Нууц үгээ оруулна уу" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={companyState.loading || employeeState.loading}
              >
                Нэвтрэх
              </Button>
              <a href="/forgot_password">Нууц үг мартсан</a>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
